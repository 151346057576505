<template>
  <router-view />
</template>

<script>
import Vue from "vue";
import { APP_QUERY } from "../../graphql";

export default Vue.extend({
  props: {
    helpCrunch: { type: Object, required: false, default: null },
  },
  apollo: {
    viewer() {
      return {
        query: APP_QUERY,
        fetchPolicy: "cache-first",
        skip: !this.helpCrunch,
        result(response) {
          if (response.error) {
            console.error(response.error);
            return;
          }
          if (!response.data) {
            console.error("No data in app viewer response");
            return;
          }

          const {
            data: { viewer },
          } = response;
          if (!viewer) {
            window.HelpCrunch("init", this.helpCrunch.appName, {
              applicationId: this.helpCrunch.appId,
              applicationSecret: this.helpCrunch.appSecret,
            });
            window.HelpCrunch("showChatWidget");
            return;
          }

          window.HelpCrunch("init", this.helpCrunch.appName, {
            applicationId: this.helpCrunch.appId,
            applicationSecret: this.helpCrunch.appSecret,
            user: {
              email: viewer.email,
              name: viewer.fullName,
              user_id: viewer.id,
            },
          });
          window.HelpCrunch("showChatWidget");
          window.HelpCrunch("updateUserData", {
            number_cards_created: viewer.statsNumberCardsCreated,
            number_cards_viewed_by_recipients:
              viewer.statsNumberCardsViewedByRecipients,
            has_been_a_recipient: viewer.statsHasBeenARecipient,
            has_contributed_to_a_card_viewed_by_a_recipient:
              viewer.statsHasContributedToCardViewedByARecipient,
          });
        },
      };
    },
  },
});
</script>
