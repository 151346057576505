import Vue from "vue";
import App from "./components/App.vue";
import VueRouter from "vue-router";
import VueApollo from "vue-apollo";
import ToastPlugin from "vue-toast-notification";
import { appsyncClient, djangoClient } from "./api/clients";
import "./styles/main.scss";
import "vue-toast-notification/dist/theme-sugar.css";
import { createRouter } from "./router";
import PageLoading from "./components/ui/PageLoading.vue";
import Loading from "../common/components/Loading.vue";
import JcButton from "../common/components/JcButton.vue";
import formatMoney from "./utils/formatMoney";
import formatPercentage from "./utils/formatPercentage";
import formatBytes from "../common/utils/formatBytes";
import Ziggeo from "vue-ziggeo";
import VModal from "vue-js-modal";
import VueCompositionAPI from "@vue/composition-api";

import "vue-progress-path/dist/vue-progress-path.css";
import VueProgress from "vue-progress-path";
import Vuelidate from "vuelidate";
import VueClipboard from "vue-clipboard2";
import VTooltip from "v-tooltip";

import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";

Vue.use(VueCompositionAPI);
Vue.use(Vuelidate);
Vue.use(VueProgress);
Vue.use(VueRouter);
Vue.use(VueApollo);
Vue.use(Ziggeo);
Vue.use(VueClipboard);
Vue.use(VModal);
Vue.use(VTooltip);
Vue.use(ToastPlugin);

Vue.component("page-loading", PageLoading);
Vue.component("loading", Loading);
Vue.component("jc-button", JcButton);

Vue.filter("money", formatMoney);
Vue.filter("percentage", formatPercentage);
Vue.filter("bytes", formatBytes);

Vue.directive("appear", {
  bind(el, binding) {
    // Create a mutation observer to watch for changes in the DOM
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "style"
        ) {
          // Check if the element is visible
          if (
            el.style.display !== "none" &&
            el.style.visibility !== "hidden" &&
            !el.hidden
          ) {
            // Call the callback function provided in the directive
            binding.value();
            // Once the callback is called, disconnect the observer
            observer.disconnect();
          }
        }
      });
    });

    // Start observing the element
    observer.observe(el, { attributes: true });
  },
});

// Global ziggeo settings.
/* global ZiggeoApi */
ZiggeoApi.V2.Locale.mediaLocale.register(
  { "ba-videorecorder.uploading": "Please wait: Uploading" },
  10,
);
ZiggeoApi.V2.Locale.mediaLocale.register(
  { "ba-videorecorder.verifying": "Please wait: Verifying" },
  10,
);

Sentry.init({
  dsn: "https://f04e987460a44390a4fbecbe620a8bbc@o34027.ingest.sentry.io/5211808",
  integrations: [new VueIntegration({ Vue, attachProps: true })],
  beforeSend(event) {
    // Modify the event here
    // eslint-disable-next-line no-prototype-builtins
    if (event.hasOwnProperty("breadcrumbs")) {
      for (const breadcrumb of event.breadcrumbs) {
        if (breadcrumb.category === "permissions.handledByZiggeo") {
          event.fingerprint = ["permissions.handledByZiggeo"];
        }
      }
    }
    return event;
  },
});

export default ({ helpCrunch }) => {
  if (process.env.NODE_ENV === "development") {
    console.info("Help Crunch config", helpCrunch);
  }

  const apolloProvider = new VueApollo({
      defaultClient: djangoClient,
      clients: { appsync: appsyncClient },
      errorHandler(error) {
        if (error.message.includes("Permission Denied")) {
          window.location.replace("/accounts/login/");
        }
      },
    }),
    router = createRouter();

  return new Vue({
    router,
    apolloProvider,
    components: { App },
    data: { helpCrunch },
    template: "<app :help-crunch='this.helpCrunch' />",
  });
};
