<template>
  <button
    type="button"
    :class="{
      btn: true,
      [`btn-${colour}`]: true,
      'full-width-mob': fullWidthMobile,
      [className]: !!className,
    }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    // TODO: Way to enforce enum?
    // teal, pink, link
    colour: {
      type: String,
      required: true,
    },
    fullWidthMobile: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: undefined,
    },
  },
};
</script>
