/* global SERVER_ENV, LOCALSTACK_APPSYNC_URL */

// include trailing slash
const staticUrl = {
  local: "/static/",
  development: "https://joycards-storage-dev.s3.amazonaws.com/static/",
  staging: "https://joycards-storage-staging.s3.amazonaws.com/static/",
  production: "https://joycards-storage-production.s3.amazonaws.com/static/",
};

const graphqlUrlDjango = {
  // local: "http://localhost:8027/graphql",
  local: "https://backend.joycards-dev.launchlab-staging.net/graphql",
  development: "https://backend.joycards-dev.launchlab-staging.net/graphql",
  staging: "https://joycards-staging.net/graphql",
  production: "https://backend.joy.cards/graphql",
};

const graphqlUrlAppsync = {
  local: LOCALSTACK_APPSYNC_URL,
  development: "https://api.joycards-dev.launchlab-staging.net/graphql",
  production: "https://api.joy.cards/graphql",
};

const ziggeoApiKey = {
  local: "85e493b5b0ad66315b54b08283727b8a",
  development: "85e493b5b0ad66315b54b08283727b8a",
  staging: "85e493b5b0ad66315b54b08283727b8a",
  production: "5d794df9cecc686dde4c005c3b055d48",
};

const stripeKey = {
  local: "pk_test_51MOfDNK9quc32Be37RY6EZkJEa5tMtXjCyvo6dpPzvDBHHiJ7Wo1Ub2ka8MkQd09CvHpcf4kN9k31BD8Yrk2y9As002DKcpjAS",
  development: "pk_test_51MOfDNK9quc32Be37RY6EZkJEa5tMtXjCyvo6dpPzvDBHHiJ7Wo1Ub2ka8MkQd09CvHpcf4kN9k31BD8Yrk2y9As002DKcpjAS",
  staging: "pk_test_51MOfDNK9quc32Be37RY6EZkJEa5tMtXjCyvo6dpPzvDBHHiJ7Wo1Ub2ka8MkQd09CvHpcf4kN9k31BD8Yrk2y9As002DKcpjAS",
  production: "todo: activate production to get a key",
}

export default {
  graphqlUrlDjango: graphqlUrlDjango[SERVER_ENV],
  graphqlUrlAppsync: graphqlUrlAppsync[SERVER_ENV],
  staticUrl: staticUrl[SERVER_ENV],
  ziggeoApiKey: ziggeoApiKey[SERVER_ENV],
  stripeKey: stripeKey[SERVER_ENV],
  maxVideoDurationSeconds: 60,
  // minVideoDurationSeconds currently required to
  // resolve https://github.com/Ziggeo/ziggeo-client-sdk/issues/37
  minVideoDurationSeconds: 5,
};
