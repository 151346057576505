import VueRouter from "vue-router";

export const createRouter = () =>
  new VueRouter({
    mode: "history",
    base: "/",
    routes: [
      {
        path: "",
        component: () => import("../components/GlobalLayout.vue"),
        children: [
          {
            path: "",
            redirect: "/dashboard/",
          },
          {
            path: "/dashboard",
            redirect: "/dashboard/cards",
            component: () => import("../components/Layout.vue"),
            children: [
              {
                path: "account",
                component: () => import("../components/account/Account.vue"),
                name: "account",
              },
              {
                path: "faqs",
                component: () => import("../components/Faqs.vue"),
                name: "faqs",
              },
              {
                path: "cards",
                component: () => import("../components/cards/Cards.vue"),
                redirect: "/dashboard/cards/created",
                name: "cards-base",
                children: [
                  {
                    path: "created",
                    component: () => import("../components/cards/Created.vue"),
                    name: "cards-created",
                  },
                  {
                    path: "collab",
                    component: () => import("../components/cards/Collab.vue"),
                    name: "cards-collab",
                  },
                  {
                    path: "received",
                    component: () => import("../components/cards/Received.vue"),
                    name: "cards-received",
                  },

                  /*
                   * {
                   * path: "create",
                   * name: "cards-create",
                   * component: () => import("../components/cards/CreateSelectPlan.vue"),
                   * meta: { hideNavigation: true },
                   * },
                   * {
                   * path: "create/:planId",
                   * component: () =>
                   * import("../components/cards/edit/EditCardBase.vue"),
                   * children: [
                   *  {
                   *    path: "",
                   *    name: "cards-create-with-plan",
                   *    component: () =>
                   *      import("../components/cards/edit/EditCard.vue"),
                   *  },
                   *  {
                   *    path: "pay",
                   *    name: "cards-create-pay",
                   *    component: () => import("../components/cards/CreatePayForPlan.vue"),
                   *    meta: { hideNavigation: true },
                   *  },
                   *  {
                   *    path: "recipients",
                   *    component: () =>
                   *      import("../components/cards/edit/RecipientInfo.vue"),
                   *    name: "cards-create-recipients",
                   *    meta: { hideNavigation: true },
                   *  },
                   * ],
                   * },
                   */
                  {
                    path: "create",
                    component: () =>
                      import("../components/cards/edit/EditCardBase.vue"),
                    children: [
                      {
                        path: "",
                        name: "cards-create",
                        component: () =>
                          import("../components/cards/edit/EditCard.vue"),
                      },
                      {
                        path: "recipients",
                        component: () =>
                          import("../components/cards/edit/RecipientInfo.vue"),
                        name: "cards-create-recipients",
                        meta: { hideNavigation: true },
                      },
                    ],
                  },
                  {
                    path: "edit/:id",
                    component: () =>
                      import("../components/cards/edit/EditCardBase.vue"),
                    children: [
                      {
                        path: "",
                        component: () =>
                          import("../components/cards/edit/EditCard.vue"),
                        name: "cards-edit",
                      },
                      {
                        path: "change-plan",
                        component: () =>
                          import("../components/cards/edit/ChangePlan.vue"),
                        name: "cards-edit-change-plan",
                        meta: { hideNavigation: true },
                      },
                      {
                        path: "change-plan/:planId",
                        component: () =>
                          import("../components/cards/edit/ChangePlanPay.vue"),
                        name: "cards-edit-change-plan-pay",
                        meta: { hideNavigation: true },
                      },
                      {
                        path: "recipients",
                        component: () =>
                          import("../components/cards/edit/RecipientInfo.vue"),
                        name: "cards-edit-recipients",
                        meta: { hideNavigation: true },
                      },
                      {
                        path: "video",
                        component: () =>
                          import("../components/cards/edit/AddVideo.vue"),
                        name: "cards-edit-add-video",
                        meta: { hideNavigation: true },
                      },
                      {
                        path: "v2/video",
                        component: () =>
                          import("../components/cards/edit/AddVideoDev.vue"),
                        name: "cards-edit-add-video-dev",
                        meta: { hideNavigation: true },
                      },
                      {
                        path: "invite",
                        component: () =>
                          import("../components/cards/edit/InviteOthers.vue"),
                        name: "cards-edit-invite-others",
                        meta: { hideNavigation: true },
                      },
                      {
                        path: "send",
                        component: () =>
                          import("../components/cards/edit/SendCard.vue"),
                        name: "cards-edit-send",
                        meta: { hideNavigation: true },
                      },
                      {
                        path: ":contributionId",
                        component: () =>
                          import(
                            "../components/cards/edit/ContributionPreview.vue"
                          ),
                        name: "cards-edit-contribution-preview",
                        meta: { hideNavigation: true },
                      },
                    ],
                  },
                  {
                    path: "collab/:id",
                    component: () =>
                      import("../components/cards/EditContribution.vue"),
                    name: "cards-edit-contribution",
                    meta: { hideNavigation: true },
                  },
                  {
                    path: "collab/v2/:id",
                    component: () =>
                      import("../components/cards/EditContributionDev.vue"),
                    name: "cards-edit-contribution-dev",
                    meta: { hideNavigation: true },
                  },
                  {
                    path: "received/:id",
                    component: () =>
                      import("../components/cards/ViewReceived.vue"),
                    name: "cards-view-received",
                    meta: { hideNavigation: true },
                  },
                  {
                    path: "received/v2/:id",
                    component: () =>
                      import("../components/cards/ViewReceivedDev.vue"),
                    name: "cards-view-received-dev",
                    meta: { hideNavigation: true },
                  },
                ],
              },
            ],
          },
          {
            path: "*",
            component: () => import("../components/Layout.vue"),
            children: [
              {
                path: "/",
                component: () => import("../components/PageNotFound.vue"),
              },
            ],
          },
        ],
      },
    ],
  });
