function formatMoney(amount) {
  const dollars = Math.floor(amount / 100);
  if (amount === dollars * 100) {
    return `$${dollars}`;
  }
  const cents = Math.round(amount - dollars * 100);
  return `$${dollars}.${cents.toString().padStart(2, "0")}`;
}

export default formatMoney;
