import createVueApp from "./app";

if (process.env.NODE_ENV === "local") {
  // import("@xstate/inspect").then((module) => {
  //   module.inspect({ iframe: false });
  // });
  // Import { inspect } from "@xstate/inspect";
  /*
   * Inspect({
   *   // options
   *   // url: 'https://stately.ai/viz?inspect', // (default)
   *   Iframe: false, // open in new window
   * });
   */
}

const helpCrunchAppSecret = process.env.HELP_CRUNCH_APP_SECRET;

export const app = createVueApp({
  helpCrunch: helpCrunchAppSecret
    ? {
        appId: 1,
        appName: "joycards",
        appSecret: helpCrunchAppSecret,
      }
    : undefined,
});

app.$router.onReady(() => {
  app.$mount(document.body.children[0]);
});
