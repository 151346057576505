const suffixes = ["B", "KB", "MB", "GB", "TB"],
  kb = 1024;

function formatBytes(bytes) {
  if (bytes === 0) {
    return "0B";
  }

  const i = Math.floor(Math.log(bytes) / Math.log(kb)),
    baseAmount = parseFloat(bytes / kb ** i),
    suffix = suffixes[i];
  if (baseAmount === Math.round(baseAmount)) {
    return baseAmount + suffix;
  }
  return baseAmount.toFixed(2) + suffix;
}

export default formatBytes;
