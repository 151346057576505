import gql from "graphql-tag";

export const CARDS_CREATED_QUERY = gql`
  query cardsCreated {
    cardsCreated {
      id
      title
      receiverName
    }
  }
`;

export const CARDS_RECEIVED_QUERY = gql`
  query cardsReceived {
    cardsReceived {
      id
      title
      receiverName
      useNewVideoBackend
    }
  }
`;

export const CARD_FROM_CONTRIBUTOR_INVITE = gql`
  query cardFromContributorInviteSlug($contributorInviteSlug: String!) {
    cardFromContributorInviteSlug(
      contributorInviteSlug: $contributorInviteSlug
    ) {
      __typename
      id
      maxUploadSizeMb
      maxVideoLength
    }
  }
`;

// TODO: should we just use card id or pk rather than mix them up...
export const CARD_DETAIL = gql`
  query Card($cardPk: String!) {
    card(cardPk: $cardPk) {
      __typename
      id
      title
      maxUploadSizeMb
      maxVideoLength
      receiverName
      newContributionsAllowed
      useNewVideoBackend
      contributions {
        id
        videoProvider
        videoToken
        videoUrl
        status
        enhancedVideoStatus
        updatedAt
        thumbnail
        aspect
        mirrorMode
        videoRotation
      }
    }
  }
`;

export const CARD_RECEIVER_INFO = gql`
  query Card($cardPk: String!) {
    card(cardPk: $cardPk) {
      id
      title
      receiverName
      useNewVideoBackend
    }
  }
`;

export const CONTRIBUTIONS_QUERY = gql`
  query contributions {
    contributions {
      id
      card {
        id
        title
        receiverName
        useNewVideoBackend
      }
    }
  }
`;

export const ACCOUNT_QUERY = gql`
  query viewer {
    viewer {
      id
      email
      hasPassword
      hasSocialAccountGoogle
      isBetaTester
    }
  }
`;

export const CONTRIBUTION_DETAIL = gql`
  query Contribution($contributionPk: String!) {
    contribution(contributionPk: $contributionPk) {
      id
      videoProvider
      videoToken
      status
      enhancedVideoStatus
      updatedAt
      thumbnail
      aspect
      videoUrl
      mirrorMode
      videoRotation
    }
  }
`;

export const EDIT_CONTRIBUTION_DETAIL = gql`
  query Contribution($contributionPk: String!) {
    contribution(contributionPk: $contributionPk) {
      id
      videoProvider
      videoToken
      videoUrl
      status
      enhancedVideoStatus
      updatedAt
      thumbnail
      aspect
      mirrorMode
      videoRotation
      card {
        id
        contributorInviteSlug
        title
        receiverName
        contributions {
          id
          videoProvider
          videoUrl
          videoToken
          thumbnail
          status
          enhancedVideoStatus
          updatedAt
          mirrorMode
          videoRotation
          aspect
        }
      }
    }
  }
`;

export const VIEWER_IS_CARD_CREATOR = gql`
  query viewerIsCardCreator($cardId: String!) {
    viewerIsCardCreator(cardId: $cardId)
  }
`;

export const GET_PRESIGNED_URL_MUTATION = gql`
  mutation getVideoUploadSignedUrl($cardId: ID, $videoId: ID!) {
    getVideoUploadSignedUrl(cardId: $cardId, videoId: $videoId) {
      url
      fields {
        name
        value
      }
    }
  }
`;

/*
 * This mutation also triggers the backend stream video processor
 * which handles video uploaded to the S3 bucket.
 */
export const GET_SIGNED_URL_FOR_STREAMING_MUTATION = gql`
  mutation getSignedUrlForStreaming($cardId: ID, $videoId: ID!) {
    getSignedUrlForStreaming(cardId: $cardId, videoId: $videoId) {
      url
      fields {
        name
        value
      }
    }
  }
`;

export const ATTACH_UPLOADED_VIDEO_MUTATION = gql`
  mutation attachUploadedVideo(
    $videoId: ID!
    $videoUrl: String!
    $cardId: ID
    $contributionId: ID
  ) {
    attachUploadedVideo(
      videoId: $videoId
      videoUrl: $videoUrl
      cardId: $cardId
      contributionId: $contributionId
    )
  }
`;

export const CLOSE_RECORDING = gql`
  mutation closeRecording(
    $cardId: ID
    $videoId: ID!
    $contributionId: ID
    $fileSuffix: String!
  ) {
    closeRecording(
      cardId: $cardId
      videoId: $videoId
      contributionId: $contributionId
      fileSuffix: $fileSuffix
    )
  }
`;

export const DELETE_CARD_MUTATION = gql`
  mutation deleteCardMutation($cardId: String!) {
    deleteCard(cardPk: $cardId) {
      success
    }
  }
`;

export const APP_QUERY = gql`
  query viewer {
    viewer {
      id
      email
      fullName
      statsNumberCardsCreated
      statsNumberCardsViewedByRecipients
      statsHasBeenARecipient
      statsHasContributedToACardViewedByARecipient
    }
  }
`;

export const DELETE_CONTRIBUTION_MUTATION = gql`
  mutation deleteContributionMutation($videoId: ID!, $cardId: ID) {
    deleteContribution(videoId: $videoId, cardId: $cardId)
  }
`;

export const SUBMIT_CONTRIBUTION_MUTATION = gql`
  mutation submitContributionMutation(
    $videoId: ID
    $cardId: ID
    $contributionId: ID
    $mirrorMode: Boolean!
    $rotation: Int!
  ) {
    submitContribution(
      videoId: $videoId
      cardId: $cardId
      contributionId: $contributionId
      mirrorMode: $mirrorMode
      rotation: $rotation
    )
  }
`;

export const REMOVE_VIDEO_FROM_CONTRIBUTION_MUTATION = gql`
  mutation removeVideoFromContributionMutation(
    $cardId: ID!
    $contributionId: ID!
    $videoId: ID
  ) {
    removeVideoFromContribution(
      cardId: $cardId
      contributionId: $contributionId
      videoId: $videoId
    )
  }
`;

// django mutation as we don't currently have
// contribution type in appsync
// vars: contributionId, rotation, mirror
export const EDIT_CONTRIBUTION_MUTATION = gql`
  mutation editContributionMutation($input: EditContributionInput!) {
    editContribution(input: $input) {
      contribution {
        __typename
        id
        mirrorMode
        videoRotation
      }
    }
  }
`;
