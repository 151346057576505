import createApolloClientDjango from "./createApolloClientDjango";
import createApolloClientAppsync from "./createApolloClientAppsync";
import environment from "../../../environment";

export const djangoClient = createApolloClientDjango({
  uri: environment.graphqlUrlDjango,
});

export const appsyncClient = createApolloClientAppsync({
  uri: environment.graphqlUrlAppsync,
});
