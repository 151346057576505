// Is polyfill needed only for tests?
import "cross-fetch/polyfill";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import Cookies from "js-cookie";

/* Placeholder */
import gql from "graphql-tag";

export const typeDefs = gql`
  type Item {
    id: ID!
    text: String!
    done: Boolean!
  }
`;

/* End placeholder */

const cache = new InMemoryCache();

export default function ({ uri }) {
  const httpLink = createHttpLink({
      uri,
      credentials: "include",
    }),
    authLink = setContext((_, { headers }) => {
      // Only used in dev as a kind of hack since can't use cookies
      /* global DEV_SESSION_ID */
      let token = Cookies.get("joycards_sessionid");
      if (DEV_SESSION_ID) {
        token = DEV_SESSION_ID;
        Cookies.set("joycards_sessionid", DEV_SESSION_ID);
      }
      return {
        headers: {
          ...headers,
          authorization: token,
        },
      };
    });

  return new ApolloClient({
    cache,
    typeDefs,
    link:
      process.env.NODE_ENV === "local" ? authLink.concat(httpLink) : httpLink,
  });
}
